<template>
	<contract-report />
</template>
<script>
import ContractReport from '@/components/contract/report/ContractReport.vue';

export default {
	name: 'ContractReportView',
	components: { ContractReport },
};
</script>
